<template>
  <div>
    <v-tabs
      class="custom-navbar"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
      v-if="isAuthenticated"
      v-model="selected_tab"
    >
      <template v-for="item in expenseTabList">
        <v-tab :key="item.key">
          <router-link :to="item.link">
            <template v-if="isGPVorStaff ? item.key === 0 : item.key === 1">
              <template v-if="globalState.my_incidence_counts.expenses > 0">
                <v-badge color="pink" bottom dot class="bottom_center_badge">
                  {{ item.label }}
                </v-badge>
              </template>
              <template v-else>{{ item.label }}</template>
            </template>
            <template
              v-else-if="isGPVorStaff ? item.key === 1 : item.key === 3"
            >
              <template v-if="globalState.my_incidence_counts.liquidation > 0">
                <v-badge color="pink" bottom dot class="bottom_center_badge">
                  {{ item.label }}
                </v-badge>
              </template>
              <template v-else>{{ item.label }}</template>
            </template>
            <template
              v-else-if="isGPVorStaff ? item.key === -1 : item.key === 2"
            >
              <template
                v-if="globalState.team_pending_approval_counts.expenses > 0"
              >
                <v-badge color="pink" bottom dot class="bottom_center_badge">
                  {{ item.label }}
                </v-badge>
              </template>
              <template v-else>{{ item.label }}</template>
            </template>
            <template
              v-else-if="isGPVorStaff ? item.key === -1 : item.key === 4"
            >
              <template
                v-if="globalState.team_pending_approval_counts.liquidation > 0"
              >
                <v-badge color="pink" bottom dot class="bottom_center_badge">
                  {{ item.label }}
                </v-badge>
              </template>
              <template v-else>{{ item.label }}</template>
            </template>
            <template v-else>{{ item.label }}</template>
          </router-link>
        </v-tab>
      </template>
    </v-tabs>

    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "expenses",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "isSPV",
      "isGPV",
      "isAdmin",
      "isSubAdmin",
      "isBrand",
      "isStaff",
      "user",
      "globalState",
    ]),
    isGPVorStaff() {
      return !(!this.isGPV && !this.isStaff);
    },
    isAdminOrSubadmin() {
      return this.isAdmin || this.isSubAdmin;
    },
    expenseTabList() {
      if (this.isGPVorStaff) {
        return this.gpv_staff_Tabs;
      } else if (this.isAdminOrSubadmin) {
        return this.admin_subadmin_Tabs;
      } else {
        return this.mainTabs;
      }
    },
    analysisTab() {
      return {
        label: this.$t("Analysis"),
        key: 0,
        link: "/expenses/analysis",
      };
    },
    myApprovalTab() {
      return {
        label: this.$t("My Approval"),
        key: 1,
        link: "/expenses/approval/my",
      };
    },
    myTeamApprovalTab() {
      return {
        label: this.$t("My Team Approval"),
        key: 2,
        link: "/expenses/approval/team",
      };
    },
    myLiquidationTab() {
      return {
        label: this.$t("My Liquidation"),
        key: 3,
        link: "/expenses/liquidation/my",
      };
    },
    myTeamLiquidationTab() {
      return {
        label: this.$t("My Team Liquidation"),
        key: 4,
        link: "/expenses/liquidation/team",
      };
    },
    solredTab() {
      return {
        label: this.$t("Solred"),
        key: 5,
        link: "/expenses/solred",
      };
    },
    mainTabs() {
      let tabs = [
        this.analysisTab,
        this.myApprovalTab,
        this.myTeamApprovalTab,
        this.myLiquidationTab,
        this.myTeamLiquidationTab,
      ];
      let i = 0;
      tabs = tabs.map((item) => {
        item.key = i;
        i++;
        return item;
      });
      return tabs;
    },
    admin_subadmin_Tabs() {
      let tabs = [
        this.analysisTab,
        this.myApprovalTab,
        this.myTeamApprovalTab,
        this.myLiquidationTab,
        this.myTeamLiquidationTab,
        this.solredTab,
      ];
      let i = 0;
      tabs = tabs.map((item) => {
        item.key = i;
        i++;
        return item;
      });
      return tabs;
    },
    gpv_staff_Tabs() {
      let tabs = [this.myApprovalTab, this.myLiquidationTab];
      let i = 0;
      tabs = tabs.map((item) => {
        item.key = i;
        i++;
        return item;
      });
      return tabs;
    },
    selected_tab: {
      set(selected_tab) {},
      get() {
        let selected_tab_arr = this.gpv_staff_Tabs;
        if (!this.isGPV && !this.isStaff) {
          selected_tab_arr = this.mainTabs;
        }
        if (this.isAdmin || this.isSubAdmin) {
          selected_tab_arr = this.admin_subadmin_Tabs;
        }
        console.log("this.selected_tab_arr - ", selected_tab_arr);
        console.log(this.$route.fullPath);
        let route_fullpath = this.$route.fullPath;
        let paths = this.$route.fullPath.split("/");
        let selected_tab = selected_tab_arr.filter(
          (tab_item) => this.$route.fullPath === tab_item.link
        );
        if (selected_tab.length === 0) {
          selected_tab = selected_tab_arr.filter((tab_item) =>
            this.$route.fullPath.includes(tab_item.link)
          );
        }
        if (selected_tab.length > 0) {
          return selected_tab[0].key;
        } else {
          if (route_fullpath.includes("liquidation/detail")) {
            console.log(paths);
            if (paths.length > 4) {
              if (paths[4] === "team") {
                selected_tab = selected_tab_arr.filter((tab_item) =>
                  tab_item.link.includes("liquidation/team")
                );
              } else {
                selected_tab = selected_tab_arr.filter((tab_item) =>
                  tab_item.link.includes("liquidation/my")
                );
              }
              return selected_tab[0].key;
            }
          }
          return selected_tab_arr[0].key;
        }
      },
    },
  },
  mounted() {
    this.$store.dispatch("auth/getGlobalState");
  },
};
</script>

<style></style>
